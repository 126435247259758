$(document).ready(function () {

    $('.repeater-partenaire').repeater({
        show: function () {
            $(this).slideDown();
        }
    });

    $("table.repeater-partenaire").on("change", ".SelectPart", function (event) {
        var idUser = $(this)[0].value;

        row = $(this).closest("tr");

        $.get('/liste/partenaire/' + idUser, function (data) {
            row.find('span.fonction').text(data.fonction);
            row.find('span.tel').text(data.tel);
            row.find('span.mail').text(data.email);
        });

    });

});