$(document).ready(function () {
    var counter = 0;

    $("#addrow-reunion-invite").on("click", function () {
        var newRow = $("<tr>");
        var cols = "";

        cols += '<td class="uk-text-center">' +
            '<select id="reunion_presence" name="reunion_presence" class="uk-form-width-medium">\n' +
            '   <option value="0">Non</option>\n' +
            '   <option value="1">Oui</option>\n' +
            '</select>' + '</td>';
        cols += '<td class="uk-text-center">' +
            '<select id="reunion_presence" name="reunion_presence" class="uk-form-width-medium">\n' +
            '   <option value="0">Non</option>\n' +
            '   <option value="1">Oui</option>\n' +
            '</select>' +
            '</td>';

        cols += '<td class="uk-text-center">' +
            '<a href="" class="ibtnDel uk-icon-button uk-icon-trash"></a>' +
            '</td>';
        newRow.append(cols);
        $("table.tab-reunion-invite").append(newRow);
        counter++;
    });



    $("table.tab-reunion-invite").on("click", ".ibtnDel", function (event) {
        $(this).closest("tr").remove();
        counter -= 1
    });


});