$(document).ready(function() {
    $('#frmEditPlannification').formValidation({
        framework: "uikit",
        button: {
            selector: '#bntSubmit',
            disabled: 'disabled'
        },
        fields: {
            dossier_id: {
                validators: {
                    notEmpty: {
                        message: "Le dossier suivi est requis"
                    }
                }
            },
            contrat_id: {
                validators: {
                    notEmpty: {
                        message: "Le dossier suivi est requis"
                    }
                }
            },
            type_event_id: {
                validators: {
                    notEmpty: {
                        message: "Le type de rendez-vous est requis"
                    }
                }
            },
            date_event: {
                validators: {
                    notEmpty: {
                        message: "La date du rendez-vous est requise"
                    }
                }
            }
        }
    });

});