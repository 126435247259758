$(document).ready(function () {

    $('.repeater-family').repeater({
        show: function () {
            $(this).slideDown();
        },
        isFirstItemUndeletable: true,
        isFirstItemCreate: true
    });

});