$(document).ready(function() {
    $('#frmEditAccount').formValidation({
        framework: "uikit",
        button: {
            selector: '#bntSubmit',
            disabled: 'disabled'
        },
        fields: {
            nom: {
                validators: {
                    notEmpty: {
                        message: "Le nom est requis"
                    }
                }
            },
            prenom: {
                validators: {
                    notEmpty: {
                        message: "Le prénom est requis"
                    }
                }
            },
            date_naissance: {
                validators: {
                    notEmpty: {
                        message: "La date de naissance est requise"
                    }
                }
            },
            adresse: {
                validators: {
                    notEmpty: {
                        message: "L'adresse est requise"
                    }
                }
            },
            ville: {
                validators: {
                    notEmpty: {
                        message: "La ville est requise"
                    }
                }
            },
            code_postal: {
                validators: {
                    notEmpty: {
                        message: "Le code postal est requis"
                    }
                }
            }
        }
    });

});