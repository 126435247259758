$(document).ready(function() {
    $('#frmEditReunion').formValidation({
        framework: "uikit",
        button: {
            selector: '#bntSubmit',
            disabled: 'disabled'
        },
        fields: {
            title: {
                validators: {
                    notEmpty: {
                        message: "Le titre est requis"
                    }
                }
            },
            date_event: {
                validators: {
                    notEmpty: {
                        message: "La date est requise"
                    }
                }
            },
            content_model: {
                validators: {
                    notEmpty: {
                        message: "Le compte-rendu est requis"
                    }
                }
            }
        }
    });


    $('#frmEditInvitation').formValidation({
        framework: "uikit",
        button: {
            selector: '#bntSubmit',
            disabled: 'disabled'
        },
        fields: {
            title: {
                validators: {
                    notEmpty: {
                        message: "Le titre est requis"
                    }
                }
            },
            date_event: {
                validators: {
                    notEmpty: {
                        message: "La date est requise"
                    }
                }
            },
            content_model: {
                validators: {
                    notEmpty: {
                        message: "Le compte-rendu est requis"
                    }
                }
            }
        }
    });

    $('#frmEditCourrier').formValidation({
        framework: "uikit",
        button: {
            selector: '#bntSubmit',
            disabled: 'disabled'
        },
        fields: {
            dossier_id: {
                validators: {
                    notEmpty: {
                        message: "Le dossier est requis"
                    }
                }
            },
            date_event: {
                validators: {
                    notEmpty: {
                        message: "La date est requise"
                    }
                }
            }
        }
    });

});