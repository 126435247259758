$(document).ready(function () {

    /*
    Convertion du module date en Français
     */
    $('input[type="date"]').on('focusin', function () {

        var i18n = { months : ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
            weekdays : ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
        };

        var datepicker = UIkit2.datepicker(this, { i18n: i18n  });
    });


    //calcul age par date de naissance
    $('#date_naissance').on("hide.uk.datepicker", function()
    {
        var age = getAge($(this).val());
        if(!isNaN(age)){
            $('#age').text(age + ' ans');
        }
    });


    //calcul date présumée d'accouchement
    $('#date_debut_grossesse').on("hide.uk.datepicker", function()
    {
        var DateGrossesse = new moment($(this).val());
        var dtAcc = DateGrossesse.add(9, 'month').format('DD/MM/YYYY');

        $('#date_theorique_accouchement').text(dtAcc);
    });

    //calcul age par date de naissance des enfants
    $('.date_naissance_enfant').on("hide.uk.datepicker", function()
    {
        var age = getAge($(this).val());
        if(!isNaN(age)){
            $itm = $(this).closest('.uk-grid').find('.age_enfant');
            $itm.text(age + ' ans');
        }
    });

});

function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }

    return age;
}