$(document).ready(function() {
    $('#frmEditContrat').formValidation({
        framework: "uikit",
        button: {
            selector: '#bntSubmit',
            disabled: 'disabled'
        },
        fields: {
            user_id: {
                validators: {
                    notEmpty: {
                        message: "L'accompagnant est requis"
                    }
                }
            },
            dossier_id: {
                validators: {
                    notEmpty: {
                        message: "Le dossier suivi est requis"
                    }
                }
            },
            type_accompagnement: {
                validators: {
                    notEmpty: {
                        message: "Le type d'accompagnement est requis"
                    }
                }
            },
            date_contrat: {
                validators: {
                    notEmpty: {
                        message: "La date du contrat est requise"
                    }
                }
            },
            nb_heures_affecte: {
                validators: {
                    notEmpty: {
                        message: "Le nombre d'heure à effectuer est requis"
                    }
                }
            },
            statut: {
                validators: {
                    notEmpty: {
                        message: "Le statut du contrat est requis"
                    }
                }
            }
        }
    });

});