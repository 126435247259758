$(document).ready(function () {

    $('#btnDownloadStats').click(function() {

        tablesToExcel(
            ['lstStats','lstStats1','lstStats2','lstStats3'],
            ['Statistique','Statistique par établissements','Statistique par classes','Statistique par situation scolaire'],
            'Statistique.xls',
            'Excel');
    });


    // function getImageCallback (event, chart) {
    //     console.log(chart.getImageURI());
    // }
    //
    // getImageCallback('ready', 'staffratio');

});