$(document).ready(function() {
    $('#frmEditUser').formValidation({
        framework: "uikit",
        trigger: 'blur',
        button: {
            selector: '#bntSubmit',
            disabled: 'disabled'
        },
        fields: {
            last_name: {
                validators: {
                    notEmpty: {
                        message: "Le nom est requis"
                    }
                }
            },
            role_id: {
                validators: {
                    notEmpty: {
                        message: "Le droit d'accès est requis"
                    }
                }
            }
        }
    });

});