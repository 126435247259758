$(document).ready(function () {

    $('.repeater-listes').repeater({
        show: function () {
            $(this).slideDown();
        }
    });

    $('#show-passord').click(function (){
        var x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    });

});