$(document).ready(function () {

    //Au chargement de la apge
    TypeEvent();

    //Après changement du type d'intervenant
    $("#type_event_id").on("change", function () {
        TypeEvent();
    });

});

function TypeEvent() {
    $val = $("#type_event_id").val();

    switch ($val) {
        case "1":
        case "2":
            $('.row-dossier').hide();
            $('.row-contrat').show();
            break;

        case "3":
        case "4":
            $('.row-dossier').show();
            $('.row-contrat').hide();
            break;

        default:
            $('.row-dossier').show();
            $('.row-contrat').hide();
            break;

    }
}