$(document).ready(function () {

    $('#classe_id').change(function () {
        $('#classe').hide();

        if($(this)[0].options[$(this).val()].label == "Autres"){
            $('#classe').show();
        }
    });

    $('#etablissement_id').change(function () {
        $('#etablissement').hide();

        if($(this)[0].options[$(this).val()].label == "Autres"){
            $('#etablissement').show();
        }
    });

    $('#last_classe_id').change(function () {
        $('#last_classe').hide();

        if($(this)[0].options[$(this).val()].label == "Autres"){
            $('#last_classe').show();
        }
    });

    $('#last_etablissement_id').change(function () {
        $('#last_etablissement').hide();

        if($(this)[0].options[$(this).val()].label == "Autres"){
            $('#last_etablissement').show();
        }
    });

});